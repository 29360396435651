import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import MetaCards from "../components/common/meta-cards"
import Hero from "../components/article/hero"
import FeaturedSection from "../components/article/feature"
import ArticleContent from "../components/article/content"
import FAQs from "../components/article/faqShort"
import TipsFromTeam from "../components/shared/tips_from_team"
import Visit from "../components/shared/visit"

const Article = props => {
  useEffect(() => {
    document.querySelector("body").classList.add("resource-inner-page")
  }, [])

  return (
    <Layout>
      <Seo title={props.data.articles.title} />
      <MetaCards data={props.data.articles.webMeta} />
      <Hero articles={props.data.articles} />
      <FeaturedSection articles={props.data.articles} />
      {props.data.articles.content.map(item => (
        <ArticleContent key={item.id} item={item} />
      ))}
      <div className={props.data.articles.hideFaQs && "hideFAQ"}>
        {props.data.articles.hideFaQs}
        <FAQs articles={props.data.articles} />
      </div>
      <TipsFromTeam data={props.data.articles.tipsFromTeam} services={true} />
      <Visit data={props.data.articles.scheduleAVisit} />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ($slug: String!) {
    articles: contentfulResourceArticles(slug: { eq: $slug }) {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      id
      image {
        file {
          url
        }
      }
      category
      title
      slug
      excerpt {
        excerpt
      }
      date
      keywords
      introText {
        raw
      }
      rightSideBar {
        title
        links {
          ... on ContentfulLinkTemplate {
            id
            label
            url
          }
        }
      }
      newPatients {
        image {
          file {
            url
          }
        }
        title
        content {
          content
        }
        linkLabel
        linkUrl
      }
      content {
        id
        fullWidth
        imageToLeft
        fullHeightImage
        content {
          raw
          references {
            contentful_id
            __typename
            url
          }
        }
      }
      hideFaQs
      faqs {
        title
        content {
          content
        }
        faqs {
          id
          question
          theAnswer {
            raw
          }
        }
      }

      tipsFromTeam {
        title
        content
        tips {
          id
          label
          shortDescription
          url
          image {
            file {
              url
            }
          }
        }
      }

      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
      }
    }
  }
`
